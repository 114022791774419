<template>
	<div>
		<el-form ref="form" :model="form" label-width="250px">
			<el-form-item label="店铺负责人姓名" :required="true">
			    <el-input v-model="form.boss_name" style="width: 200px;" placeholder="请输入店铺负责人姓名"></el-input>
			</el-form-item>
			<el-form-item label="店铺负责人身份证号" :required="true">
			    <el-input v-model="form.boss_idNumber" type="number" style="width: 300px;" placeholder="请输入店铺负责人身份证号"></el-input>
			</el-form-item>
			<el-form-item label="店铺负责人手持身份证头像面" :required="true">
				<div class="flex">
					<div>
						<el-image
						    style="width: 148px;height: 148px;margin-right: 20px;"
							v-if="form.identity1_url" 
						    :src="imgUrl+form.identity1_url"
							:preview-src-list="[imgUrl+form.identity1_url]" 
						    >
						  </el-image>
					</div>
					<div>
						<el-upload  list-type="picture-card"
						   action
							v-if="check_status!=3"
						   :http-request="e=>handleUpload1(e)"
							:show-file-list="false"
						   >
						   <i class="el-icon-plus"></i>
						 </el-upload>
					</div>
				</div>
			</el-form-item>
			<el-form-item label="店铺负责人身份证国徽面" :required="true">
			    <div class="flex">
			    	<div>
						<el-image
						    style="width: 148px;height: 148px;margin-right: 20px;"
							v-if="form.identity2_url" 
						    :src="imgUrl+form.identity2_url"
							:preview-src-list="[imgUrl+form.identity2_url]" 
						    >
						  </el-image>
			    	</div>
			    	<div>
			    		<el-upload  list-type="picture-card"
						   action
						   v-if="check_status!=3"
			    		   :http-request="e=>handleUpload2(e)"
			    			:show-file-list="false"
			    		   >
			    		   <i class="el-icon-plus"></i>
			    		 </el-upload>
			    	</div>
			    </div>
			</el-form-item>
			<el-form-item label="店铺负责人营业执照" v-if="!(!form.business_url && check_status==3)">
			    <div class="flex">
			    	<div>
						<el-image 
						    style="width: 148px;height: 148px;margin-right: 20px;"
							v-if="form.business_url" 
						    :src="imgUrl+form.business_url" 
							:preview-src-list="[imgUrl+form.business_url]" 
						     >
						  </el-image>
			    	</div>
			    	<div>
			    		<el-upload  list-type="picture-card"
						   action
							v-if="check_status!=3"
			    		   :http-request="e=>handleUpload3(e)"
			    			:show-file-list="false"
			    		   >
			    		   <i class="el-icon-plus"></i>
			    		 </el-upload>
			    	</div>
			    </div>
			</el-form-item>
			<el-form-item>
			    <el-button type="primary" @click="confirm" v-if="check_status!=3">确定</el-button>
			</el-form-item>
		</el-form>	
	</div>
</template>

<script>
	
	export default {
		
		data(){
			return {
				form:{
					boss_name:'',
					boss_idNumber:'',
					business_url:'',
					identity1_url:'', //手持身份证头像面
					identity2_url:'', //身份证国徽面
				},
				check_status:'3', // 0未提交 1 已提交 2 已驳回 3已同意
			}
		},
		mounted() {
			document.title='商户认证'
			this.lodeData()
		},
		methods:{
			confirm(){
				if(!this.form.boss_name) return this.$message.error('请输入负责人姓名')
				if(!this.form.boss_idNumber) return this.$message.error('请输入负责人身份证号码')
				if(!this.form.identity1_url) return this.$message.error('请上传负责人手持身份证头像面')
				if(!this.form.identity2_url) return this.$message.error('请上传负责人身份证国徽面')
				this.$post('/pc/index/setCheckShopData',this.form,res=>{
					let result = res.data
					if(result.code==200){
						this.$message.success('提交成功')
					}
				})
				
			},
			lodeData(){
				this.$post('/pc/index/getCheckShopData',{},res=>{
					let result = res.data
					if(result.code==200){
						for(let key in this.form){
							this.form[key] = result.data[key]
						}
						this.check_status = result.data.check_status
					}
				})
			},
			handleUpload1(e){
				var formdata = new FormData()
				formdata.append('file', e.file)
				formdata.append('cid',sessionStorage.getItem('cid'))
				formdata.append('oldImg',this.form.identity1_url)
				formdata.append('key','identity1_url')
				this.$post('/scs/index/addShopCheckImg',formdata,res=>{
					let result =res.data
					if(result.code==200){
						this.form.identity1_url = result.data.url
					    return this.$message.success(result.msg)
					}
					  return  this.$message.error('上传失败')
				})
			},
			handleUpload2(e){
				var formdata = new FormData()
				formdata.append('file', e.file)
				formdata.append('cid',sessionStorage.getItem('cid'))
				formdata.append('oldImg',this.form.identity2_url)
				formdata.append('key','identity2_url')
				this.$post('/scs/index/addShopCheckImg',formdata,res=>{
					let result =res.data
					if(result.code==200){
						this.form.identity2_url = result.data.url
					    return this.$message.success(result.msg)
					}
					  return  this.$message.error('上传失败')
				})
			},
			handleUpload3(e){
				var formdata = new FormData()
				formdata.append('file', e.file)
				formdata.append('cid',sessionStorage.getItem('cid'))
				formdata.append('oldImg',this.form.business_url)
				formdata.append('key','business_url')
				this.$post('/scs/index/addShopCheckImg',formdata,res=>{
					let result =res.data
					if(result.code==200){
						this.form.business_url = result.data.url
					    return this.$message.success(result.msg)
					}
					  return  this.$message.error('上传失败')
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	
</style>